import React from "react"

import Layout from "../components/Layout"
import RatesAndFees from "../components/RatesAndFees"
import SEO from "../components/seo"

const RatesAndFeesPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Rates and fees" />
    <RatesAndFees />
  </Layout>
)

export default RatesAndFeesPage
